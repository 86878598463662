
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import moment from "moment";
import FilterModel from "../../components/filter-model";
import GetHardwareLogsRequest from "@/dataSource/api/logger-micro-service/services/models/request/log-getter-service/GetHardwareLogsRequest";
import PageRequest from "@/dataSource/api/pagination-helper/lib/PageRequest";
import GetHardwareLogsResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/GetHardwareLogsResponse";

@Options({
  name: "HardwareLogsView",
  components: {
    TableView,
  },
})
export default class HardwareLogsView extends Vue {
  @Prop({ default: new FilterModel() }) filter: FilterModel;
  innerFilter: GetHardwareLogsRequest;
  // items: HardwareMonitoringData[] = [];
  page: PageRequest = { Take: 20, Skip: 0 };
  count = 0;
  noPagination = false;
  isLoading = false;

  @Watch("filter", { deep: true })
  onFilter() {
    this.refresh();
  }

  m(value: Date) {
    return moment(value).format("DD.MM.YYYY hh:mm");
  }
  mounted() {
    this.refresh();
  }

  async refresh() {
    if (!this.filter) {
      return;
    }
    let response: GetHardwareLogsResponse = null;

    this.isLoading = true;
    // this.innerFilter.ToDate = (this.filter as any).DateTo;
    // this.innerFilter.FromDate = (this.filter as any).DateFrom;

    this.innerFilter = {
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Type: this.filter.HardwareLogsType,
    };
    try {
      response = await this.$api.LogGetterService.getHardwareLogsAsync(
        this.innerFilter
      );
    } catch (error) {
      console.log(`error:`, error);
      this.isLoading = false;
    }
    this.isLoading = false;

    //   this.items = response.Data.map((item) => ({
    //     Type: item.Type,
    //     Date: item.Date,
    //     Value: item.Value,
    //   }));
    // }
    //событие срабатывет при изменении пагинации при просмотре
    //   onPaginationChanged(page: number) {
    //     let skip = (page - 1) * this.page.Take;
    //     if (this.page.Skip === skip) {
    //       return;
    //     }

    //     this.page.Skip = skip;
    //     this.refresh();
    // //   }
    //   getIntervalTime(dateStart: Date, dateEnd: Date): number {
    //     return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
    //   }
    //   onReadMore(id: string) {
    //     console.log(`id=`, id);
    //   }
  }
}
